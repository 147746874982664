<template>
  <section class="pop__recuite" :class="{'show': PopStatus}">
    <div class="pop__bgs" @click="closePop"></div>
    <div class="pop__contents inner" id="recuite__pops">
      <div class="recuit__title">
        <div class="social__info">
            <strong>{{popData.title}}</strong>
            <div class="social__tag">
                <span>学历：{{popData.degree}}</span>
                <span>年龄：{{popData.age}}</span>
                <span>招聘人数：{{popData.job_number}}人</span>
                <span>工作地点：{{popData.place}}</span>
                <span>工作经验：{{popData.experience}}</span>
            </div>
        </div>
        <div class="social__time">
          <a v-if="popData.end_status == 1" href="javascript:;" @click="offPop(popData.id)">填写简历</a>
           <a v-else href="javascript:;">招聘截止</a>
        </div>
      </div>
      <div class="recuite__texts" v-html="popData.notes">
      </div>
    </div>
  </section>
</template>

<script>
import { useMyStore } from '../../store';
export default {
  props: {
    popData:{
        type: Object,
        required: true
    }
  },
  computed: {
    PopStatus() {
      return useMyStore().RecuitePop;
    }
  },
  methods: {
    closePop() {
      useMyStore().setPoprecuiteHide();
    },
    offPop(id) {
      this.$router.push(`/biographical/${id}`)
    }
  }
}
</script>

<style>

</style>